import {
    Action,
    createSlice,
    PayloadAction,
    ThunkDispatch,
} from "@reduxjs/toolkit";

import { VESTING_DETAILS } from "@src/config";
import { Vesting } from "@src/web3";
import { RootState } from "@src/store";
import { mapWallets } from "@src/util";
import { ContractWallet, Wallet } from "@src/ts/interfaces";

interface WalletState {
    wallets: {
        [idx: number]: {
            [strategy_id: number]: Wallet[];
        };
    };
    current: {
        [idx: number]: {
            [strategy_id: number]: string;
        };
    };
    search: {
        [idx: number]: {
            [strategy_id: number]: string;
        };
    };
}

const initial = VESTING_DETAILS.reduce(
    (acc, _, idx) => ({ ...acc, [idx]: {} }),
    {},
);

const initialState = {
    wallets: initial,
    current: initial,
    search: initial,
} as WalletState;

const walletSlice = createSlice({
    name: "wallet",
    initialState,
    reducers: {
        setCurrent(
            state,
            action: PayloadAction<{
                idx: number;
                strategy_id: number;
                addr: string;
            }>,
        ) {
            const { idx, strategy_id, addr } = action.payload;
            state.current[idx][strategy_id] = addr;
        },
        setSearch(
            state,
            action: PayloadAction<{
                idx: number;
                strategy_id: number;
                search: string;
            }>,
        ) {
            const { idx, strategy_id, search } = action.payload;
            state.search[idx][strategy_id] = search;
        },
        setWallets(
            state,
            action: PayloadAction<{
                idx: number;
                strategy_id: number;
                wallets: Wallet[];
            }>,
        ) {
            const { idx, strategy_id, wallets } = action.payload;
            state.wallets[idx][strategy_id] = wallets;
        },
    },
});

export const updateWallets = (
    idx: number,
    strategy_id: number,
): ((dispatch: ThunkDispatch<RootState, void, Action>) => void) => {
    const { contract } = VESTING_DETAILS[idx];
    const vesting = Vesting(contract);
    return (dispatch: ThunkDispatch<RootState, void, Action>): void =>
        vesting
            .getWhitelistPool(strategy_id)
            .then(async (wallets: ContractWallet[]) => {
                const strategy = await vesting.getVestingInfo(strategy_id);
                dispatch(
                    setWallets({
                        idx,
                        strategy_id,
                        wallets: wallets.map((w: ContractWallet) =>
                            mapWallets(w, strategy),
                        ),
                    }),
                );
            });
};

export const { setCurrent, setWallets, setSearch } = walletSlice.actions;
export default walletSlice.reducer;

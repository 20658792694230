import { useState } from "react";
import { useToasts } from "react-toast-notifications";

import { Button, Input } from "@src/components";
import { useAppDispatch, useContract } from "@src/hooks";
import { VESTING_DETAILS } from "@src/config";
import VestingABI from "@src/abi/DecubateVestingV2.json";
import { setSearch, updateWallets } from "@src/features/wallets";
import { parseUnits } from "@ethersproject/units";
import { useToken } from "@src/hooks/useToken";

export const Manual: React.FC<{ idx: number; strategy_id: number }> = ({
    idx,
    strategy_id,
}) => {
    const { token_ticker, contract, token_address } = VESTING_DETAILS[idx];
    const { addToast } = useToasts();
    const [wallet, setWallet] = useState("");
    const [amount, setAmount] = useState("");
    const [loading, setLoading] = useState(false);
    const vesting = useContract(contract, VestingABI, true);
    const dispatch = useAppDispatch();

    const { decimals } = useToken(token_address);

    const handleAdd = async () => {
        setLoading(true);
        addToast("Awaiting successful transaction(s)", {
            appearance: "info",
        });
        try {
            const tx = await vesting.addWhitelist(
                wallet,
                parseUnits(amount, decimals),
                strategy_id,
            );

            await tx.wait();
            addToast(`Successfully added ${wallet} to strategy`, {
                appearance: "success",
            });
            setTimeout(
                () => dispatch(setSearch({ idx, strategy_id, search: wallet })),
                1500,
            );
            setWallet("");
            setAmount("");
            dispatch(updateWallets(idx, strategy_id));
        } catch (err) {
            addToast(err.data?.message || err.message, {
                appearance: "error",
            });
        }

        setLoading(false);
    };

    return (
        <div>
            <Input
                name="wallet"
                id="wallet"
                label="Wallet address"
                className="my-2"
                value={wallet}
                onChange={(e) => setWallet(e.target.value)}
            />
            <Input
                name="amount"
                id="amount"
                label={`Amount (${token_ticker})`}
                className="my-2"
                value={amount}
                type="number"
                onChange={(e) => setAmount(e.target.value)}
            />
            <Button
                className="my-4 w-full"
                onClick={handleAdd}
                loading={loading}
                disabled={loading}
                id="add_strategy"
            >
                Add
            </Button>
        </div>
    );
};

import React from "react";
import Link from "next/link";

import { BarChartIcon, SettingsIcon } from "@src/components/Icon";

import { useRouter } from "next/router";

import { AddStrategyButton } from "./AddStrategyButton";

export const RoutingButtons: React.FC<{ idx: number }> = ({ idx }) => {
    const router = useRouter();
    const settings_route = router.pathname.includes("settings")
        ? `/manage/${idx}`
        : `/manage/${idx}/settings`;
    const analytics_route = router.pathname.includes("analytics")
        ? `/manage/${idx}`
        : `/manage/${idx}/analytics`;

    return (
        <div className="flex space-x-4 items-center">
            <Link href={settings_route}>
                <a>
                    <button
                        className="p-2 bg-level-one rounded border border-white border-opacity-5  text-secondary hover:text-primary"
                        id="settings"
                    >
                        <SettingsIcon />
                    </button>
                </a>
            </Link>
            <Link href={analytics_route}>
                <a>
                    <button
                        className="p-2 bg-level-one rounded border border-white border-opacity-5  text-secondary hover:text-primary"
                        id="analytics"
                    >
                        <BarChartIcon />
                    </button>
                </a>
            </Link>
            <AddStrategyButton idx={idx} />
        </div>
    );
};

import { LEVEL_TWO_COLOR } from "@src/config";
import React from "react";
import ReactTooltip from "react-tooltip";

export const Tooltip: React.FC<{
    text: string;
    active?: boolean;
    className?: string;
}> = ({ children, text, active = true, className }) => {
    return (
        <div className={className}>
            <div data-tip={active ? text : undefined}>{children}</div>
            <ReactTooltip
                className="text-white rounded opacity-100"
                backgroundColor={LEVEL_TWO_COLOR}
            />
        </div>
    );
};

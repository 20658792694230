import { FooterProps } from "@decub8/ui";

import {
    COMPANY_NAME,
    IS_DARK_MODE,
    FOOTER_LINKS,
    DISABLE_DECUBATE_FOOTER,
    SOCIAL_LINKS,
} from "@src/config";

export const useFooter = (): FooterProps => {
    const links: { name: string; handleClick: () => void }[] = FOOTER_LINKS
        ? FOOTER_LINKS.map(({ name, href, target }) => ({
              name,
              handleClick: () => window.open(href, target),
          }))
        : [];

    const social_links: { type: string; url: string }[] = SOCIAL_LINKS
        ? SOCIAL_LINKS.map(({ type, url }) => ({
              type,
              url,
          }))
        : [];

    return {
        links,
        social_links,
        company_name: COMPANY_NAME,
        show_powered_by: !DISABLE_DECUBATE_FOOTER,
        audited_by_img_urls: IS_DARK_MODE
            ? [
                  "https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/Hacken-Logo-Dark.svg",
                  "https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/Certik-Logo-Dark.svg",
              ]
            : [
                  "https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/Hacken-Logo-Light.svg",
                  "https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/Certik-Logo-Light.svg",
              ],
        decubate_img_url: IS_DARK_MODE
            ? "https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/Decubate-Logo-Dark.svg"
            : "https://platform-s3-publicread.s3.eu-central-1.amazonaws.com/Decubate-Logo-Light.svg",
        className: "max-w-screen-lg mx-auto px-5",
    };
};

import { useAppSelector, useAppDispatch } from "@src/hooks";
import { setConnectionErrorModalOpen } from "@src/features/layout";
import { Modal } from "..";

export const ConnectionErrorModal: React.FC = () => {
    const open = useAppSelector(
        (state) => state.layout.connection_error_modal_open,
    );
    const dispatch = useAppDispatch();

    if (!open) {
        return null;
    }

    return (
        <Modal onClose={() => dispatch(setConnectionErrorModalOpen(false))}>
            <div className="text-left">
                <h3 className="text-2xl my-3">
                    <strong>Something went wrong</strong>
                </h3>
                <p className="my-3">
                    If connection cannot be made, please try this:
                </p>
                <ol className="ml-4 my-3">
                    <li>Close all wallet windows</li>
                    <li>Re-launch your browser</li>
                    <li>Try connecting again</li>
                </ol>
            </div>
        </Modal>
    );
};

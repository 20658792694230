import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LayoutState {
    connecting: boolean;
    connection_error_modal_open: boolean;
    incorrect_network_modal_open: boolean;
    safety_modal_open: boolean;
    claim_modal_open: boolean;
}

const initialState = {
    connecting: false,
    connection_error_modal_open: false,
    incorrect_network_modal_open: false,
    safety_modal_open: false,
    claim_modal_open: false,
} as LayoutState;

const layoutSlice = createSlice({
    name: "layout",
    initialState,
    reducers: {
        setConnecting(state, action: PayloadAction<boolean>) {
            state.connecting = action.payload;
        },
        setConnectionErrorModalOpen(state, action: PayloadAction<boolean>) {
            state.connection_error_modal_open = action.payload;
        },
        setIncorrectNetworkModalOpen(state, action: PayloadAction<boolean>) {
            state.incorrect_network_modal_open = action.payload;
        },
        setSafetyModalOpen(state, action: PayloadAction<boolean>) {
            state.safety_modal_open = action.payload;
        },
        setClaimModalOpen(state, action: PayloadAction<boolean>) {
            state.claim_modal_open = action.payload;
        },
    },
});

export const {
    setConnecting,
    setConnectionErrorModalOpen,
    setIncorrectNetworkModalOpen,
    setSafetyModalOpen,
    setClaimModalOpen,
} = layoutSlice.actions;
export default layoutSlice.reducer;

import React from "react";

import { CardProps } from "@src/ts/props";

export const Card: React.FC<CardProps> = ({ handleClick }) => {
    const main_classes =
        "py-4 rounded text-left relative w-full py-3 px-5 mb-10 border border-white border-opacity-5 bg-level-one";

    return (
        <div className={main_classes}>
            <div className="absolute top-2 right-2 p-3">
                <button className="cursor-pointer" onClick={handleClick}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </button>
            </div>
            <h4 className="text-xl sm:text-2xl mb-2" id="confirm_text">
                <strong>Congrats, you are in!</strong>
            </h4>
            <p>
                We are glad to have you here, now you can view and claim your
                tokens
            </p>
        </div>
    );
};

import { useRouter } from "next/router";

import { Button } from "@src/components";
import { useAppSelector } from "@src/hooks";
import { parseBalance } from "@src/util";
import { IS_DIFFERENT_ACCENT, VESTING_DETAILS } from "@src/config";
import { formatUnits } from "@ethersproject/units";
import { useToken } from "@src/hooks/useToken";
import { UserVesting } from "@src/ts/interfaces";

export const VestingSummary: React.FC<{
    current: UserVesting;
    handleRelease?: (callback?: () => void) => Promise<void>;
}> = ({ current, handleRelease }) => {
    const router = useRouter();
    const idx = Number(router.query.idx);
    const { token_ticker, token_address } = VESTING_DETAILS[idx];
    const state = useAppSelector((state) => state.vesting);

    const { decimals } = useToken(token_address);

    const locked = parseBalance(
        current?.total_vesting
            .sub(current.already_released)
            .sub(current.releasable) || 0,
        decimals,
    );
    const claimed = parseBalance(current?.already_released || 0, decimals);
    const total = parseBalance(current?.total_vesting || 0, decimals);

    const percent_claimed =
        (Number(formatUnits(current?.already_released || 0, decimals)) /
            Number(formatUnits(current?.total_vesting || 1, decimals))) *
        100;

    const not_valid = current?.revoked || current?.disabled;

    return (
        <div
            className={`mb-10 ${
                not_valid ? "mx-auto md:w-2/3" : "w-full md:w-2/3  md:mr-2"
            }`}
        >
            <h3 className="text-lg mb-10 text-left font-medium">
                Vesting Summary
            </h3>
            <div
                className={`bg-gradient py-3 px-5 my-2 text-left rounded md:h-48 flex items-center`}
            >
                <div className="md:flex flex-1 items-center">
                    <div className="flex-1">
                        <div className="flex items-center justify-between my-2">
                            <div>
                                <p className="my-3">Total</p>
                                <p className="my-3">Locked</p>
                                <p className="my-3">Claimed</p>
                            </div>
                            <div className="text-right relative">
                                <p className="my-3 ">
                                    {not_valid
                                        ? `0.00 ${token_ticker}`
                                        : `${total} ${token_ticker}`}
                                </p>

                                <p className="my-3 ">
                                    {not_valid
                                        ? `0.00 ${token_ticker}`
                                        : `${locked} ${token_ticker}`}
                                </p>

                                <p className="my-3">
                                    {`${claimed} ${token_ticker}`} |{" "}
                                    <span
                                        className={`${
                                            IS_DIFFERENT_ACCENT
                                                ? "text-[#a69999]"
                                                : "text-accent"
                                        }`}
                                    >
                                        {percent_claimed.toFixed(
                                            percent_claimed > 0.01 ? 2 : 3,
                                        )}{" "}
                                        %
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex-1 text-center my-5">
                        <p className="text-xs text-secondary mb-2">
                            Available Now
                        </p>
                        <h2 className="text-2xl mb-5" id="unclaimed_token">
                            <strong>
                                {parseBalance(
                                    current?.disabled
                                        ? 0
                                        : current?.releasable || "0",
                                    decimals,
                                ) + ` ${token_ticker}`}
                            </strong>
                        </h2>

                        <Button
                            onClick={() => handleRelease()}
                            disabled={
                                Number(current?.releasable || 0) === 0 ||
                                state.processing ||
                                current?.disabled
                            }
                            className="w-full md:w-auto"
                            id="release-btn"
                        >
                            {!state.processing ? (
                                Number(current?.releasable || 0) === 0 ? (
                                    "Nothing to claim"
                                ) : (
                                    "Claim all tokens"
                                )
                            ) : (
                                <div className="flex justify-center items-center">
                                    <div className="loader">
                                        <img
                                            src="/img/Spinner-Small.png"
                                            className="mx-auto"
                                            style={{
                                                width: "12px",
                                                height: "12px",
                                            }}
                                        />
                                    </div>

                                    <p className="ml-3">Transferring...</p>
                                </div>
                            )}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

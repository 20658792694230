import { useState, useEffect } from "react";

import { getERC20Contract } from "../web3";

export const useToken = (
    address: string,
): { symbol: string; decimals: number; loading: boolean } => {
    const [symbol, setSymbol] = useState("");
    const [decimals, setDecimals] = useState(18);
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState(null);

    useEffect(() => {
        let is_mounted = true;
        if (!token && address) {
            getERC20Contract(address).then((token) => setToken(token));
        }
        if (token) {
            setLoading(true);
            token.symbol().then((symbol) => {
                if (is_mounted) {
                    setSymbol(symbol);
                }
            });
            token.decimals().then((decimals) => {
                if (is_mounted) {
                    setDecimals(decimals);
                    setLoading(false);
                }
            });
        } else {
            setDecimals(18);
            setSymbol("");
        }

        return () => {
            is_mounted = false;
        };
    }, [address, token]);

    return { symbol, decimals, loading };
};

import { useState } from "react";

import { Card } from "@src/components";

import { VestingChart } from "./Chart";
import { VestingPeriod } from "./Period";
import { VestingSummary } from "./Summary";
import { UserVesting } from "@src/ts/interfaces";
import { shouldDisplayCard } from "@src/util";

const MainVesting: React.FC<{
    current: UserVesting;
    handleRelease: (callback?: () => void) => Promise<void>;
}> = ({ current, handleRelease }) => {
    const [display_card, setDisplayCard] = useState(shouldDisplayCard());

    const handleClick = () => {
        localStorage?.setItem("has_seen_card", JSON.stringify(true));
        setDisplayCard(false);
    };

    return (
        <div>
            {display_card && <Card handleClick={handleClick} />}

            <div className="md:flex items-stretch">
                <VestingSummary
                    current={current}
                    handleRelease={handleRelease}
                />
                <VestingPeriod current={current} />
            </div>

            <VestingChart strategy={current} />
        </div>
    );
};

export default MainVesting;

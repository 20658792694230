import React, { useEffect, useState } from "react";
import { Web3Provider as EthersWeb3Provider } from "@ethersproject/providers";
import { init, useConnectWallet, useSetChain } from "@web3-onboard/react";
import { ConnectedChain, WalletState } from "@web3-onboard/core";
import injectedModule from "@web3-onboard/injected-wallets";
import walletConnectModule from "@web3-onboard/walletconnect";
import ledgerModule from "@web3-onboard/ledger";
import gnosisModule from "@web3-onboard/gnosis";

import { CHAIN_ID, COMPANY_NAME, ONBOARD_LOGO, LOGO } from "@src/config";
import { NETWORKS } from "@src/constants";
import { getRPCUrl } from "@src/util";

const injected = injectedModule();
const ledger = ledgerModule();
const wallet_connect = walletConnectModule({
    projectId: "7a09ff565008f298e79b036497b383eb",
    version: 2,
    requiredChains: [CHAIN_ID],
});
const gnosis = gnosisModule();

export const NETWORK = NETWORKS[CHAIN_ID];

interface IWeb3Context {
    wallet: WalletState;
    account?: string;
    connecting: boolean;
    connect: () => Promise<WalletState[]>;
    disconnect: () => Promise<WalletState[]>;
    connectedChain: ConnectedChain;
    settingChain: boolean;
    provider?: EthersWeb3Provider;
}

const Web3Context = React.createContext<IWeb3Context>(null);

const web3onboard = init({
    wallets: [injected, ledger, wallet_connect, gnosis],
    chains: [
        {
            id: "0x" + CHAIN_ID.toString(16),
            token: NETWORK.symbol,
            label: NETWORK.network_name,
            rpcUrl: getRPCUrl(),
        },
    ],
    appMetadata: {
        name: `${COMPANY_NAME} vesting`,
        logo: ONBOARD_LOGO || LOGO,
        description: `Where ${COMPANY_NAME} investors can claim their vested tokens`,
    },
    accountCenter: {
        desktop: { enabled: false },
        mobile: { enabled: false },
    },
});

export default web3onboard;

export const Web3onboardProvider: React.FC = ({ children }) => {
    const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
    const [{ connectedChain, settingChain }, setChain] = useSetChain();
    const [prev_account, setPrevAccount] = useState("");
    const account = wallet && wallet.accounts[0].address;

    useEffect(() => {
        const previouslyConnectedWallets = JSON.parse(
            window.localStorage.getItem("connectedWallets"),
        );
        if (previouslyConnectedWallets?.length > 0) {
            // You can also auto connect "silently" and disable all onboard modals to avoid them flashing on page load
            connect({
                autoSelect: {
                    label: previouslyConnectedWallets[0],
                    disableModals: true,
                },
            });
        }
    }, []);

    useEffect(() => {
        if (
            account &&
            account !== prev_account &&
            parseInt(connectedChain?.id, 16) !== CHAIN_ID
        ) {
            setChain({ chainId: "0x" + CHAIN_ID.toString(16) });
        }
        setPrevAccount(account);
    }, [account, connectedChain?.id]);

    useEffect(() => {
        const walletsSub = web3onboard.state.select("wallets");
        const { unsubscribe } = walletsSub.subscribe((wallets) => {
            const connectedWallets = wallets.map(({ label }) => label);
            window.localStorage.setItem(
                "connectedWallets",
                JSON.stringify(connectedWallets),
            );
        });
        return unsubscribe;
    }, []);

    return (
        <Web3Context.Provider
            value={{
                wallet,
                account,
                connecting,
                connect: () => connect(),
                disconnect: () => disconnect(wallet),
                connectedChain,
                settingChain,
                provider: wallet?.provider
                    ? new EthersWeb3Provider(wallet.provider)
                    : undefined,
            }}
        >
            {children}
        </Web3Context.Provider>
    );
};

function useWeb3Onboard(): IWeb3Context {
    return React.useContext(Web3Context);
}

export { useWeb3Onboard };

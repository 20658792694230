import { ContractInterface } from "@ethersproject/contracts";
import { BigNumber } from "@ethersproject/bignumber";

export enum VestingType {
    Linear = "0",
    Monthly = "1",
    Interval = "2",
}

export interface ContractStrategy {
    id?: number;
    name: string;
    cliff: BigNumber;
    start: BigNumber;
    duration: BigNumber;
    initialUnlockPercent: BigNumber;
    revocable: boolean;
    vestType: number;
    interval: BigNumber;
    unlockPerInterval: BigNumber;
    timestamps: BigNumber[];
}

export interface ContractWallet {
    disabled: boolean;
    revoke: boolean;
    dcbAmount: BigNumber;
    distributedAmount: BigNumber;
    wallet: string;
}

export interface Strategy {
    id?: number;
    name: string;
    cliff: string;
    initial_unlock: number;
    start_date: string;
    end_date: string;
    revocable: boolean;
    type: VestingType;
    interval: number;
    unlock_per_interval: string;
    timestamps: number[];
}

export interface UserVesting extends Strategy {
    total_vesting: BigNumber;
    already_released: BigNumber;
    releasable: BigNumber;
    revoked: boolean;
    disabled: boolean;
}

export interface StrategyWithWallets extends Strategy {
    wallets: Wallet[];
}

export interface StrategyAnalytics extends Strategy {
    num_wallets: number;
    total_releasable: BigNumber;
    total_released: BigNumber;
    total_vested: BigNumber;
}

export interface Wallet {
    disabled: boolean;
    revoked: boolean;
    amount: string;
    released: string;
    wallet: string;
    releasable?: string;
}

export interface IContract {
    abi: ContractInterface;
    address: string;
}

import { useState } from "react";

import { ModalProps } from "@src/ts/props";

import { Button } from "..";
import { LOGO } from "@src/config";

export const Modal: React.FC<ModalProps> = ({
    onClose,
    children,
    className = "p-5",
    bearbones = false,
}) => {
    const [inside_card, setInsideCard] = useState(false);

    return (
        <div
            data-testid="modal-div"
            className="min-w-screen h-screen animated fadeIn faster fixed left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover"
            id="modal-id"
            onClick={() => !inside_card && onClose()}
        >
            <div className="absolute bg-black opacity-80 inset-0 z-0"></div>
            <div
                data-testid="mouse-enter-leave-div"
                className={`w-full max-w-lg relative mx-auto my-auto rounded shadow-lg bg-level-one ${className}`}
                onMouseEnter={() => setInsideCard(true)}
                onMouseLeave={() => setInsideCard(false)}
            >
                {bearbones ? (
                    children
                ) : (
                    <div>
                        <div className="py-3 flex justify-between items-center">
                            <img src={LOGO} className="h-8" />
                            <button onClick={onClose} className="mr-2">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                        {children}
                        <div className="p-3  mt-2 text-center space-x-4 md:block">
                            <Button className="w-full" onClick={onClose}>
                                Close
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

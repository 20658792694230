import { useEffect } from "react";
import { useRouter } from "next/router";
import { useWeb3Onboard } from "@src/hooks/useWeb3Onboard";
import { CHAIN_ID } from "@src/config";

export const useWeb3Auth = (): void => {
    const { account, connectedChain } = useWeb3Onboard();
    const router = useRouter();
    useEffect(() => {
        if (!account || CHAIN_ID !== parseInt(connectedChain?.id, 16))
            router.push("/");
    }, [account, connectedChain?.id]);
};

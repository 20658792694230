import React from "react";

export const WalletLogos: React.FC = () => {
    return (
        <div className="sm:flex items-center justify-around p-3 mt-12 mx-auto text-center">
            <div className={`flex`}>
                <div className={`m-3 flex-1`}>
                    <img
                        src="/icons/Metamask-Logo.svg"
                        className="mx-auto"
                        style={{ width: "100px", height: "100px" }}
                    />
                </div>

                <div className={`m-3 flex-1`}>
                    <img
                        src="/icons/TrustWallet-Logo.svg"
                        className="mx-auto"
                        style={{ width: "100px", height: "100px" }}
                    />
                </div>
            </div>
        </div>
    );
};

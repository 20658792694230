import Head from "next/head";

import { MetaProps } from "@src/ts/props";
import { COMPANY_NAME, MOBILE_BANNER } from "@src/config";

export const Meta: React.FC<MetaProps> = ({ title, keywords, description }) => {
    return (
        <Head>
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1"
            />
            <meta name="keywords" content={keywords} />
            <meta name="description" content={description} />
            <meta charSet="utf-8" />
            <meta property="og:image" content={MOBILE_BANNER} />
            <link rel="icon" href="/favicon.ico" />
            <title>
                {COMPANY_NAME} | {title}
            </title>
            <script
                async
                src="https://www.googletagmanager.com/gtag/js?id=G-LMJXRRWHW0"
            />
            <script
                dangerouslySetInnerHTML={{
                    __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-LMJXRRWHW0', { page_path: window.location.pathname });
            `,
                }}
            />
            <link
                href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
                rel="stylesheet"
            />
        </Head>
    );
};

Meta.defaultProps = {
    title: "Vesting",
    keywords:
        "blockchain, cryptocurrency, crypto, incubator, smart vesting, tokenized assets, smart contracts, community rewards, social incubation, easy staking, fair allocation, cross pooling",
    description: `Where ${COMPANY_NAME} investors can go to claim their tokens.`,
};

import React from "react";

import { useAppDispatch, useAppSelector } from "@src/hooks";
import { setIncorrectNetworkModalOpen } from "@src/features/layout";

import { Modal } from "..";
import { CHAIN_ID } from "@src/config";
import { NETWORKS } from "@src/constants";

const network = NETWORKS[CHAIN_ID];

export const IncorrectNetworkModal: React.FC = () => {
    const open = useAppSelector(
        (state) => state.layout.incorrect_network_modal_open,
    );
    const dispatch = useAppDispatch();

    if (!open) {
        return null;
    }

    return (
        <Modal onClose={() => dispatch(setIncorrectNetworkModalOpen(false))}>
            <div className="text-left">
                <h1 className="my-3">
                    <strong>INCORRECT NETWORK</strong>
                </h1>

                <p className="my-3">
                    We currently use {network.name}. Please change network and
                    retry to connect.
                </p>
            </div>
        </Modal>
    );
};

import React from "react";
import Link from "next/link";
import { useWeb3Onboard } from "@src/hooks/useWeb3Onboard";

import { formatChainScanLink, shortenHex } from "@src/util";

import { Button } from "@src/components";

export const Account: React.FC = () => {
    const { account, connect } = useWeb3Onboard();

    if (!account) {
        return (
            <div className="sm:p-3 p-1 md:p-0">
                <Button onClick={() => connect()}>
                    <p
                        className="sm:text-sm text-xs cursor-pointer"
                        data-testid="connect-wallet"
                    >
                        Connect wallet
                    </p>
                </Button>
            </div>
        );
    }
    return (
        <Link href={formatChainScanLink("Account", account)}>
            <a target="_blank" rel="noopener noreferrer">
                <Button className="hover:underline">
                    <span className="flex items-center">
                        <div className="flex-1" data-testid="wallet-address">
                            {shortenHex(account)}
                        </div>
                    </span>
                </Button>
            </a>
        </Link>
    );
};

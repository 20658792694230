import React from "react";
import { Provider } from "react-redux";
import { ToastProvider } from "react-toast-notifications";
import { AppProps } from "next/app";

import { Layout } from "@src/components";
import { useCustomClasses } from "@src/hooks";
import { store } from "@src/store";
import { Web3onboardProvider } from "@src/hooks/useWeb3Onboard";

import "tailwindcss/tailwind.css";
import "@styles/global.scss";
import "@src/app.scss";

function MyApp({ Component, pageProps }: AppProps): JSX.Element {
    useCustomClasses();

    return (
        <Web3onboardProvider>
            <Provider store={store}>
                <ToastProvider
                    placement="bottom-right"
                    autoDismiss={true}
                    autoDismissTimeout={7000}
                >
                    <Layout>
                        <Component {...pageProps} />
                    </Layout>
                </ToastProvider>
            </Provider>
        </Web3onboardProvider>
    );
}

export default MyApp;

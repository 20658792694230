import { useWeb3Onboard } from "@src/hooks/useWeb3Onboard";
import { isOwner } from "@src/util";

export const ContractOwnerOnly: React.FC<{ owner: string }> = ({
    owner,
    children,
}) => {
    const { account } = useWeb3Onboard();

    const is_owner = account && isOwner(account, owner);

    return (
        (is_owner && <> {children}</>) || (
            <div className="text-center mt-40">
                <h3 className="text-4xl mb-4">
                    <strong>Sorry, you can't go here!</strong>
                </h3>
                <p className="text-secondary">
                    Only the owner of the contract is allowed to use this
                    feature
                </p>
            </div>
        )
    );
};

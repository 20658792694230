import { useEffect, useState } from "react";

import { useAppSelector, useAppDispatch, useContract } from "@src/hooks";
import { setWallets } from "@src/features/wallets";
import { VESTING_DETAILS } from "@src/config";
import VestingABI from "@src/abi/DecubateVestingV2.json";
import { mapWallets } from "@src/util";

import { CardItem } from "./CardItem";
import { Manual } from "./Manual";
import { RequiresSetup } from "./RequiresSetup";
import { Upload } from "./Upload";
import { WalletLookup } from "./WalletLookup";
import { ContractStrategy, ContractWallet } from "@src/ts/interfaces";

const wallet_nav_opts = ["Upload", "Enter manually"];

export const WalletManagement: React.FC<{
    idx: number;
    strategy_id?: number;
    add?: boolean;
    back: () => void;
    revocable: boolean;
}> = ({ idx, strategy_id, add, back, revocable }) => {
    const wallets =
        useAppSelector((state) => state.wallet.wallets[idx][strategy_id]) || [];

    const { contract } = VESTING_DETAILS[idx];
    const vesting = useContract(contract, VestingABI);
    const [nav, setNav] = useState(0);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (vesting && !isNaN(strategy_id)) {
            vesting
                .getVestingInfo(strategy_id)
                .then(async (strategy: ContractStrategy) => {
                    const wallets = await vesting.getWhitelistPool(strategy_id);
                    dispatch(
                        setWallets({
                            idx,
                            strategy_id,
                            wallets: wallets.map((w: ContractWallet) =>
                                mapWallets(w, strategy),
                            ),
                        }),
                    );
                });
        }
    }, [vesting]);

    if (add) {
        return <RequiresSetup back={back} />;
    }

    return (
        <div className="mt-4">
            <div className="flex items-center space-x-4">
                <CardItem
                    title={wallets.length}
                    subtitle="Wallets in this strategy"
                    id={"number-of-participants"}
                />
                <CardItem
                    title={wallets.filter(({ revoked }) => revoked).length}
                    subtitle="Wallets revoked"
                />
            </div>

            <div className="my-4">
                <p className="text-secondary mb-2">Add wallets</p>
                <div className="rounded bg-level-two p-4">
                    <div
                        className="bg-level-one border-4 border-level-one flex items-center rounded"
                        id="wallet_navigation"
                    >
                        {wallet_nav_opts.map((o, idx) => (
                            <button
                                key={o}
                                onClick={() => setNav(idx)}
                                className={`rounded flex-1 px-4 py-2 ${
                                    idx === nav
                                        ? "bg-level-two"
                                        : "text-secondary"
                                } `}
                            >
                                {o}
                            </button>
                        ))}
                    </div>

                    <div className="mt-4">
                        {nav === 0 ? (
                            <Upload idx={idx} strategy_id={strategy_id} />
                        ) : (
                            <Manual idx={idx} strategy_id={strategy_id} />
                        )}
                    </div>
                </div>
            </div>

            <WalletLookup
                revocable={revocable}
                idx={idx}
                strategy_id={strategy_id}
            />
        </div>
    );
};

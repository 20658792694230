import React, { useEffect, useState } from "react";
import { useRouter } from "next/router";

import { useAppSelector } from "@src/hooks";

import {
    Container,
    ConnectionErrorModal,
    IncorrectNetworkModal,
    PageLoader,
    Meta,
    SafetyModal,
} from "..";

import { Footer } from "./footer";
import { Header } from "./header";

export const Layout: React.FC = ({ children }) => {
    const attempted = useAppSelector((state) => state.vesting.attempted);
    const loading = useAppSelector((state) => state.vesting.loading);

    const router = useRouter();
    const [route_loading, setRouteLoading] = useState(false);

    useEffect(() => {
        router.events.on("routeChangeStart", () => {
            setRouteLoading(true);
        });
        router.events.on("routeChangeComplete", () => {
            setRouteLoading(false);
        });
        return () => {
            router.events.off("routeChangeStart", () => {
                return;
            });
            router.events.off("routeChangeComplete", () => {
                return;
            });
        };
    }, [router.events]);

    return (
        <div className="bg-main text-primary">
            <Meta />
            <Header />
            <main>
                <Container>{children}</Container>
            </main>
            <Footer />
            <ConnectionErrorModal />
            <IncorrectNetworkModal />
            <SafetyModal />
            {(!attempted || loading || route_loading) && <PageLoader />}
        </div>
    );
};

import { StrategyList } from "./StrategyList";

import { RoutingButtons } from "./RoutingButtons";
import { Strategy } from "@src/ts/interfaces";

export const ManagementPanel: React.FC<{
    idx: number;
    strategies: Strategy[];
}> = ({ idx, strategies }) => {
    return (
        <div className="w-full">
            <div className="my-8 flex items-center justify-between">
                <h4 className="text-2xl">Vesting strategies</h4>
                <RoutingButtons idx={idx} />
            </div>
            <StrategyList idx={idx} strategies={strategies} />
        </div>
    );
};

export { ContractOwnerOnly } from "./ContractOwnerOnly";
export { StrategyManagement } from "./StrategyManagement";

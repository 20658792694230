import { useState } from "react";
import { useToasts } from "react-toast-notifications";
import { formatUnits, parseUnits } from "@ethersproject/units";
import { BigNumber } from "@ethersproject/bignumber";

import { useAppSelector, useAppDispatch, useContract } from "@src/hooks";
import { Button, Input } from "@src/components";
import { updateWallets } from "@src/features/wallets";
import { VESTING_DETAILS } from "@src/config";
import VestingABI from "@src/abi/DecubateVestingV2.json";
import { useToken } from "@src/hooks/useToken";

export const AmendWallet: React.FC<{
    idx: number;
    strategy_id: number;
    handleBackClick: () => void;
}> = ({ idx, strategy_id, handleBackClick }) => {
    const curr_addr = useAppSelector(
        (state) => state.wallet.current[idx][strategy_id],
    );

    const wallets =
        useAppSelector((state) => state.wallet.wallets[idx][strategy_id]) || [];
    const current = wallets.find(({ wallet }) => wallet === curr_addr);
    const [loading, setLoading] = useState(false);
    const { addToast } = useToasts();
    const { contract, token_ticker, token_address } = VESTING_DETAILS[idx];
    const vesting = useContract(contract, VestingABI, true);
    const dispatch = useAppDispatch();
    const { decimals } = useToken(token_address);
    const [amount, setAmount] = useState(
        formatUnits(current.amount || 0, decimals),
    );

    const handleAmend = async () => {
        addToast("Awaiting successful transaction", {
            appearance: "info",
        });
        setLoading(true);
        try {
            const wei_amount = parseUnits(amount || "0", decimals);
            const tx = await vesting.setWhitelist(
                current.wallet,
                wei_amount,
                strategy_id,
            );
            await tx.wait();
            dispatch(updateWallets(idx, strategy_id));
            handleBackClick();
            addToast("Successfully amended users vesting amount", {
                appearance: "success",
            });
        } catch (err) {
            addToast(err.data?.message || err.message, {
                appearance: "error",
            });
        }
        setLoading(false);
    };
    const has_changes = !BigNumber.from(current.amount).eq(
        parseUnits(amount || "0", decimals),
    );

    return (
        <div>
            <div className="flex items-center space-x-2">
                <button onClick={handleBackClick}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M15 19l-7-7 7-7"
                        />
                    </svg>
                </button>

                <p>Amend vesting</p>
            </div>

            <p className="my-4">
                <strong>{current.wallet}</strong>
            </p>

            <Input
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                name="vest-amount"
                label="Vesting amount"
                inset={
                    <h3 className="text-lg px-4">
                        <strong>{token_ticker}</strong>
                    </h3>
                }
            />
            <div className="mt-4 border-2 border-secondary text-secondary rounded px-3 py-1">
                Warning! Changing the parameters of an active vesting strategy
                can cause errors, please think carefully before updating this
                value
            </div>

            <Button
                loading={loading}
                disabled={loading || !has_changes}
                className="w-full mt-6"
                onClick={handleAmend}
            >
                Save changes
            </Button>
        </div>
    );
};

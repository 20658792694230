import { useState } from "react";
import { useRouter } from "next/router";

import { useAppSelector, useAppDispatch } from "@src/hooks";
import { setClaimModalOpen } from "@src/features/layout";
import { ATS } from "@src/config";

import { Modal } from "@src/components/modal";
import { Button } from "@src/components/button";

export const ClaimModal: React.FC<{
    handleRelease: (callback?: () => void) => Promise<void>;
}> = ({ handleRelease }) => {
    const open = useAppSelector((state) => state.layout.claim_modal_open);
    const processing = useAppSelector((state) => state.vesting.processing);
    const dispatch = useAppDispatch();
    const router = useRouter();

    const [is_stake, setIsStake] = useState(false);

    if (!open) {
        return null;
    }

    const handleClose = () => !processing && dispatch(setClaimModalOpen(false));

    const claimToWallet = () => {
        setIsStake(false);
        handleRelease(handleClose);
    };
    const claimAndStake = () => {
        setIsStake(true);
        handleRelease(() => {
            router.push(ATS);
        });
    };

    return (
        <Modal onClose={handleClose} className="rounded" bearbones={true}>
            <div className="text-left rounded border border-white border-opacity-5">
                <div className="text-primary px-5 py-8 bg-claim-gradient rounded-t">
                    <h1 className="text-3xl mb-2">
                        <strong>Claim &amp; Stake</strong>
                    </h1>
                    <p>
                        Let your tokens work &amp; grow, generating fully
                        passive income for you by staking them.
                    </p>
                </div>
                <div className="bg-level-one text-primary px-5 py-8 space-y-4 rounded-b">
                    <Button
                        className="w-full"
                        loading={is_stake && processing}
                        disabled={processing}
                        onClick={claimAndStake}
                    >
                        Claim &amp; stake
                    </Button>
                    <Button
                        className="w-full"
                        loading={!is_stake && processing}
                        disabled={processing}
                        onClick={claimToWallet}
                        dark
                    >
                        Claim to wallet
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

import VestingABI from "./abi/DecubateVestingV2.json";
import ERC20ABI from "./abi/ERC20.json";

import { Contract } from "@ethersproject/contracts";
import { JsonRpcProvider } from "@ethersproject/providers";
import { getRPCUrl } from "./util";

const provider = new JsonRpcProvider(getRPCUrl());
export const Vesting = (address: string): Contract =>
    new Contract(address, VestingABI, provider);

export const getERC20Contract = async (address: string): Promise<Contract> => {
    await provider.ready;
    return new Contract(address, ERC20ABI, provider);
};
export default provider;

import { UserVesting } from "@src/ts/interfaces";
import { VestingSummary } from "./Summary";

const Suspended: React.FC<{
    current: UserVesting;
    disabled: boolean;
    handleRelease: (callback?: () => void) => Promise<void>;
}> = ({ current, disabled, handleRelease }) => {
    return (
        <div>
            <h1
                className="text-center text-3xl lg:text-4xl my-10"
                id="text_suspension"
            >
                <strong>
                    YOUR CONTRACT HAS <br />
                    BEEN {disabled ? "SUSPENDED" : "DISABLED"}
                </strong>
            </h1>
            <div className="md:flex items-stretch">
                <VestingSummary
                    current={current}
                    handleRelease={handleRelease}
                />
            </div>
        </div>
    );
};

export default Suspended;

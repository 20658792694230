import { useEffect, useState } from "react";

import { useAppSelector, useAppDispatch } from "@src/hooks";
import { Input } from "@src/components";
import { setCurrent, setSearch } from "@src/features/wallets";

import { AmendWallet } from "./AmendWallet";
import { WalletDetails } from "./WalletDetails";

export const WalletLookup: React.FC<{
    idx: number;
    strategy_id: number;
    revocable: boolean;
}> = ({ idx, strategy_id, revocable }) => {
    const search = useAppSelector(
        (state) => state.wallet.search[idx][strategy_id],
    );

    const curr_addr = useAppSelector(
        (state) => state.wallet.current[idx][strategy_id],
    );
    const wallets =
        useAppSelector((state) => state.wallet.wallets[idx][strategy_id]) || [];
    const current = wallets.find(({ wallet }) => wallet === curr_addr);
    const dispatch = useAppDispatch();
    const [nav, setNav] = useState(0);

    const handlePaste = async () => {
        const text = await navigator.clipboard.readText();
        dispatch(setSearch({ idx, strategy_id, search: text }));
    };
    useEffect(() => {
        const found = wallets.find(
            ({ wallet }) =>
                wallet?.trim().toLowerCase() === search?.trim().toLowerCase(),
        );
        dispatch(setCurrent({ idx, strategy_id, addr: found?.wallet || "" }));
    }, [search]);

    return (
        <div className="mt-4">
            <p className="text-secondary mb-2">Wallet lookup</p>
            <div className="bg-level-two p-4 rounded">
                {nav === 0 ? (
                    <>
                        <div className="flex items-center space-x-4">
                            <div className="flex-1">
                                <Input
                                    id="look_up"
                                    name="wallet_search"
                                    value={search}
                                    onChange={(e) =>
                                        dispatch(
                                            setSearch({
                                                idx,
                                                strategy_id,
                                                search: e.target.value,
                                            }),
                                        )
                                    }
                                />
                            </div>
                            <button
                                onClick={handlePaste}
                                className="bg-level-one p-2 rounded"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-8 w-8"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                                    />
                                </svg>
                            </button>
                        </div>
                        {current && (
                            <WalletDetails
                                idx={idx}
                                strategy_id={strategy_id}
                                handleAmendClick={() => setNav(1)}
                                revocable={revocable}
                            />
                        )}
                    </>
                ) : (
                    <AmendWallet
                        idx={idx}
                        strategy_id={strategy_id}
                        handleBackClick={() => setNav(0)}
                    />
                )}
            </div>
        </div>
    );
};

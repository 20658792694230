import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "@src/store";

export { useContract } from "./useContract";
export { useCustomClasses } from "./useCustomClasses";
export { useIsomorphicLayoutEffect } from "./useIsomorphicsLayoutEffect";
export { useWeb3Auth } from "./useWeb3Auth";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

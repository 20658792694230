export const Switch: React.FC<{
    options: string[];
    current: number;
    setCurrent: (v: number) => void;
    disabled?: boolean;
}> = ({ current, options, setCurrent, disabled = false }) => {
    return (
        <div
            className="bg-main border-4 border-main flex items-center rounded"
            id="vesting_type"
        >
            {options.map((o, idx) => (
                <button
                    data-testid="switch-btn"
                    key={o}
                    disabled={disabled}
                    onClick={() => setCurrent(idx)}
                    className={`rounded flex-1 px-4 py-2 ${
                        idx === current ? "bg-level-one" : "text-secondary"
                    } `}
                >
                    {o}
                </button>
            ))}
        </div>
    );
};

import { useEffect } from "react";

import { is_browser } from "@src/constants";
import { LEVEL_ONE_COLOR, ACCENT_COLOR } from "@src/config";
import { createClass } from "@src/util";

export const useCustomClasses = (): void => {
    useEffect(() => {
        is_browser &&
            createClass(
                ".bg-gradient",
                `background-color: transparent; background-image: radial-gradient(circle farthest-side at 150% 290%,${ACCENT_COLOR},${LEVEL_ONE_COLOR}); background-clip: border-box;`,
            );
    }, [is_browser]);
};

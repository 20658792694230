import { Switch, Button, Input } from "@src/components";
import { strategy_form_data, types } from "@src/constants";
import { VestingType } from "@src/ts/interfaces";
import { StrategyFormProps } from "@src/ts/props";

export const StrategyForm: React.FC<StrategyFormProps> = ({
    state = {},
    handleChange,
    add = false,
    handleClick,
    errors,
    loading,
    disabled,
}) => {
    const is_monthly = state.type === VestingType.Monthly;

    return (
        <div className="pt-4">
            <p className="text-secondary mb-2 text-sm">Vesting type</p>
            <Switch
                options={types.filter(
                    (_, idx) => add || idx.toString() === state.type,
                )}
                current={add ? Number(state.type) : 0}
                setCurrent={(next: number) =>
                    handleChange({
                        target: { name: "type", value: next.toString() },
                    })
                }
                disabled={!add}
            />

            {strategy_form_data
                .filter(({ types }) =>
                    types.includes(state.type as VestingType),
                )
                .map(({ name, label, type, placeholder, tooltip, inset }) => (
                    <Input
                        className={`my-4`}
                        key={name}
                        value={state[name]}
                        name={name}
                        id={name}
                        label={label}
                        error={errors[name]}
                        onChange={!add && is_monthly ? undefined : handleChange}
                        readOnly={!add && is_monthly}
                        placeholder={placeholder}
                        type={type}
                        inset={
                            inset && (
                                <div className="px-4">
                                    <strong>{inset}</strong>
                                </div>
                            )
                        }
                        tooltip={tooltip}
                    />
                ))}

            {!add && (
                <div className="mt-4 border-2 border-secondary text-secondary rounded px-3 py-1">
                    {is_monthly
                        ? "It is not possible to update the values of a monthly vesting strategy. The displayed values are for information purposes only"
                        : "Warning! Changing the parameters of an active vesting strategy can cause errors, please think carefully before updating these values"}
                </div>
            )}

            {!(!add && is_monthly) && (
                <Button
                    id="add_strategy"
                    className={"w-full my-4"}
                    onClick={handleClick}
                    loading={loading}
                    disabled={disabled || loading}
                >
                    {add ? "Add strategy" : "Save changes"}
                </Button>
            )}
        </div>
    );
};

import React from "react";

import { Button } from "@src/components";

export const RequiresSetup: React.FC<{ back: () => void }> = ({ back }) => {
    return (
        <div className="text-center my-20 px-4">
            <h2 className="text-2xl" id="guide_text">
                <strong>
                    You need to set up and create a strategy before you can
                    manage wallets
                </strong>
            </h2>

            <Button className="w-32 mt-8" dark onClick={back}>
                Back
            </Button>
        </div>
    );
};

export const CHAIN_ID = parseInt(process.env.REACT_CHAIN_ID, 10);
export const LOGO = process.env.REACT_LOGO;
export const ONBOARD_LOGO = process.env.REACT_ONBOARD_LOGO;
export const LOGO_WIDTH = process.env.REACT_LOGO_WIDTH;
export const DESKTOP_BANNER = process.env.REACT_BANNER_DESKTOP;
export const MOBILE_BANNER = process.env.REACT_BANNER_MOBILE;
export const BANNER_LINK = process.env.REACT_BANNER_LINK;
export const DISABLE_DECUBATE_FOOTER =
    process.env.REACT_DISABLE_DECUBATE_FOOTER === "true";

export const COMPANY_NAME = process.env.REACT_COMPANY_NAME;
export const COMPANY_WEBSITE = process.env.REACT_COMPANY_WEBSITE;
export const DEV_WALLETS =
    JSON.parse(process.env.REACT_DEV_WALLETS || "[]") || [];
export const TERMS_AND_CONDITIONS = process.env.REACT_TERMS_AND_CONDITIONS;
export const PRIVACY_POLICY = process.env.REACT_PRIVACY_POLICY;
export const VESTING_DETAILS = JSON.parse(
    process.env.REACT_VESTING_DETAILS || "[]",
);

export const FOOTER_LINKS = JSON.parse(process.env.REACT_FOOTER_LINKS || "[]");
export const SOCIAL_LINKS = JSON.parse(process.env.REACT_SOCIAL_LINKS || "[]");

export const ABI_VERSION = process.env.REACT_ABI_VERSION;
export const ATS = process.env.REACT_ATS;

const theme = JSON.parse(process.env.REACT_THEME || "{}");

export const ACCENT_COLOR = theme.accent;
export const IS_DARK_MODE = theme.is_dark_mode;
export const LEVEL_ONE_COLOR = theme.level_one;
export const LEVEL_TWO_COLOR = theme.level_two;
export const IS_DIFFERENT_ACCENT = theme.is_different_accent || false;

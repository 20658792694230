import dynamic from "next/dynamic";
import { useRouter } from "next/router";

import { VESTING_DETAILS } from "@src/config";
import { useMemo } from "react";
import { getDataFromStrategy } from "@src/util";
import { useToken } from "@src/hooks/useToken";
import { UserVesting } from "@src/ts/interfaces";

const VChart = dynamic(() => import("./Chart"), { ssr: false });

export const VestingChart: React.FC<{ strategy: UserVesting }> = ({
    strategy,
}) => {
    const router = useRouter();
    const idx = Number(router.query.idx);
    const { token_ticker, token_address } = VESTING_DETAILS[idx];

    const { decimals } = useToken(token_address);

    const data = useMemo(
        () => getDataFromStrategy(strategy, strategy.total_vesting, decimals),
        [strategy, decimals],
    );

    return (
        <div className="text-left">
            <h3 className="text-lg mb-10 font-medium">Vesting Schedule</h3>
            <div className="bg-level-one p-2 my-2 rounded border border-white border-opacity-5">
                <VChart
                    series={[{ name: `${token_ticker} Vesting`, data }]}
                    ticker={token_ticker}
                />
            </div>
        </div>
    );
};

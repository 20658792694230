import { useState, useEffect } from "react";
import { useWeb3Onboard } from "@src/hooks/useWeb3Onboard";
import { useRouter } from "next/router";
import { useToasts } from "react-toast-notifications";

import { CHAIN_ID, VESTING_DETAILS } from "@src/config";
import { useContract } from "@src/hooks/useContract";

import VestingABI from "@src/abi/DecubateVestingV2.json";
import { useToken } from "@src/hooks/useToken";
import { isOwner } from "@src/util";

/* eslint-disable-next-line */
declare let window: any;

export const AddToWallet: React.FC<{ className?: string }> = ({
    className,
}) => {
    const { account, connectedChain } = useWeb3Onboard();
    const { addToast } = useToasts();
    const router = useRouter();
    const idx = Number(router.query.idx);
    const [owner, setOwner] = useState("");
    const { token_ticker, token_address, contract } =
        VESTING_DETAILS[idx] || {};
    const vesting = useContract(contract, VestingABI);

    const { decimals } = useToken(token_address);

    useEffect(() => {
        let is_mounted = true;
        if (vesting && CHAIN_ID === parseInt(connectedChain?.id, 16)) {
            vesting.owner().then((o) => is_mounted && setOwner(o));
        }
        return () => {
            is_mounted = false;
        };
    }, [vesting]);

    const is_owner = account && isOwner(account, owner);

    const handleAddToWallet = async () => {
        if (is_owner) {
            router.push(`/manage/${idx}`);
            return;
        }

        try {
            await window.ethereum.request({
                method: "wallet_watchAsset",
                params: {
                    type: "ERC20",
                    options: {
                        address: token_address,
                        symbol: token_ticker,
                        decimals,
                    },
                },
            });
        } catch (error) {
            addToast(error.message, { appearance: "error" });
        }
    };
    if (!account) {
        return null;
    }

    if (isNaN(idx)) {
        return null;
    }

    return (
        <button
            data-testid="manage-btn"
            id="manage-btn"
            className={className || ""}
            onClick={handleAddToWallet}
        >
            {is_owner ? "Manage settings" : "Add " + token_ticker}
        </button>
    );
};

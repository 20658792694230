import { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { BigNumber } from "@ethersproject/bignumber";

import { useAppSelector, useAppDispatch, useContract } from "@src/hooks";
import { Button } from "@src/components";
import { updateWallets } from "@src/features/wallets";
import { VESTING_DETAILS } from "@src/config";
import VestingABI from "@src/abi/DecubateVestingV2.json";
import { parseBalance } from "@src/util";
import { useToken } from "@src/hooks/useToken";

const TextItem: React.FC<{ title: string; text: string }> = ({
    text,
    title,
}) => (
    <div className="flex items-center space-x-4 my-2">
        <div className="flex-1 text-secondary">{title}</div>
        <div>
            <strong>{text}</strong>
        </div>
    </div>
);

export const WalletDetails: React.FC<{
    idx: number;
    strategy_id: number;
    handleAmendClick: () => void;
    revocable: boolean;
}> = ({ idx, strategy_id, handleAmendClick, revocable }) => {
    const curr_addr = useAppSelector(
        (state) => state.wallet.current[idx][strategy_id],
    );

    const wallets =
        useAppSelector((state) => state.wallet.wallets[idx][strategy_id]) || [];

    const current = wallets.find(({ wallet }) => wallet === curr_addr);
    const { contract, token_ticker, token_address } = VESTING_DETAILS[idx];
    const [releasable, setReleasable] = useState(BigNumber.from(0));
    const { addToast } = useToasts();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState({
        suspend: false,
        revoke: false,
    });
    const vesting = useContract(contract, VestingABI, true);

    const { decimals } = useToken(token_address);

    useEffect(() => {
        let is_mounted = true;
        if (current && vesting) {
            vesting
                .getReleasableAmount(strategy_id, current.wallet)
                .then((amount) => is_mounted && setReleasable(amount));
        }
        return () => {
            is_mounted = false;
        };
    }, [current]);

    const handleSuspend = async () => {
        addToast("Awaiting successful transaction", {
            appearance: "info",
        });
        setLoading({ ...loading, suspend: true });
        try {
            const tx = await vesting.setVesting(
                strategy_id,
                current.wallet,
                !current.disabled,
            );
            await tx.wait();

            addToast("Successfully revoked wallet", {
                appearance: "success",
            });

            dispatch(updateWallets(idx, strategy_id));
        } catch (err) {
            addToast(err.data?.message || err.message, {
                appearance: "error",
            });
        }
        setLoading({ ...loading, suspend: false });
    };

    const handleRevoke = async () => {
        addToast("Awaiting successful transaction", {
            appearance: "info",
        });
        setLoading({ ...loading, revoke: true });
        try {
            const tx = await vesting.revoke(strategy_id, current.wallet);
            await tx.wait();

            addToast("Successfully revoked wallet", {
                appearance: "success",
            });

            dispatch(updateWallets(idx, strategy_id));
        } catch (err) {
            addToast(err.data?.message || err.message, {
                appearance: "error",
            });
        }
        setLoading({ ...loading, revoke: false });
    };

    return (
        <div className="mt-4">
            <TextItem
                title="Claimed"
                text={`${parseBalance(
                    current.released,
                    decimals,
                )} ${token_ticker}`}
            />
            <TextItem
                title="Available"
                text={`${parseBalance(releasable, decimals)} ${token_ticker}`}
            />
            <TextItem
                title="Locked"
                text={`${parseBalance(
                    BigNumber.from(current.amount)
                        .sub(current.released)
                        .sub(releasable),
                    decimals,
                )} ${token_ticker}`}
            />
            <TextItem
                title="Total"
                text={`${parseBalance(
                    current.amount,
                    decimals,
                )} ${token_ticker}`}
            />
            <TextItem
                title="Revoked"
                text={current.revoked.toString().toUpperCase()}
            />
            <TextItem
                title="Suspended"
                text={current.disabled.toString().toUpperCase()}
            />

            <Button className="w-full my-4" onClick={handleAmendClick}>
                Amend
            </Button>

            <div className="flex items-center space-x-4">
                <Button
                    disabled={!revocable || current.revoked || loading.revoke}
                    onClick={handleRevoke}
                    loading={loading.revoke}
                    dark
                    className="flex-1"
                >
                    Revoke
                </Button>
                <Button
                    id="suspend_Button"
                    className="flex-1"
                    loading={loading.suspend}
                    disabled={loading.suspend}
                    onClick={handleSuspend}
                >
                    {current.disabled ? "Unsuspend" : "Suspend"}
                </Button>
            </div>
        </div>
    );
};

import Link from "next/link";

import { Strategy } from "@src/ts/interfaces";

import { AddStrategyButton } from "./RoutingButtons/AddStrategyButton";

export const StrategyList: React.FC<{
    idx: number;
    strategies: Strategy[];
}> = ({ idx, strategies }) => {
    return strategies.length > 0 ? (
        <div
            className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-12"
            id="StrategyList"
        >
            {strategies.map(({ name }, id) => (
                <Link href={`/manage/${idx}/strategy/${id}`} key={idx + name}>
                    <a className="h-18 bg-level-one hover:bg-level-two px-4 py-4 flex items-center justify-start space-x-4 rounded border border-white border-opacity-5">
                        <h4 className="">
                            <strong>{name}</strong>
                        </h4>
                    </a>
                </Link>
            ))}
        </div>
    ) : (
        <div className="text-center">
            <img src="/img/Welcome.png" className="mx-auto my-12" />

            <h4 className="text-2xl max-w-sm mx-auto" id="welcome_text">
                <strong>Welcome to your new vesting management panel</strong>
            </h4>
            <p className="mt-4 mb-8 text-secondary">
                Let’s get started by creating your first vesting strategy
            </p>

            <AddStrategyButton className="mx-auto" idx={idx} />
        </div>
    );
};

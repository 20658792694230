import { applyMiddleware, createStore, combineReducers } from "redux";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import thunk from "redux-thunk";

import layout_reducer from "@src/features/layout";
import wallet_reducer from "@src/features/wallets";
import vesting_reducer from "@src/features/vesting";

const reducer = combineReducers({
    layout: layout_reducer,
    wallet: wallet_reducer,
    vesting: vesting_reducer,
});

export const store = createStore(reducer, applyMiddleware(thunk));

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// eslint-disable-next-line
export type AppDispatch = ThunkDispatch<RootState, void, Action>;

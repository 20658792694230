import Link from "next/link";
import { useRouter } from "next/router";

import { Button } from "@src/components";

import { COMPANY_WEBSITE, VESTING_DETAILS } from "@src/config";

const NoStrat: React.FC = () => {
    const router = useRouter();
    const idx = Number(router.query.idx);
    const {
        token_ticker,
        dex_name,
        pair_url = "",
    } = VESTING_DETAILS[idx] || {};

    return (
        <div>
            <h1 className="text-center text-6xl mt-28 mb-10">
                <strong data-testid="sales">
                    DIDN'T CATCH THE <br /> EARLY SALES?
                </strong>
            </h1>
            <p className="my-10 text-md">
                We got you covered! Now you can buy
                <br />
                {token_ticker} tokens on {dex_name}
            </p>
            <div className="flex items-center justify-center">
                <Link href={COMPANY_WEBSITE}>
                    <a
                        data-testid="home-link"
                        target="_blank"
                        className="sm:ml-6 sm:pr-3"
                        rel="noopener noreferrer"
                    >
                        <Button className="sm:w-48" dark>
                            Back Home
                        </Button>
                    </a>
                </Link>
                <Link href={pair_url}>
                    <a
                        data-testid="buy-link"
                        target="_blank"
                        className="ml-3 sm:ml-0 sm:mr-6 sm:pl-3"
                    >
                        <Button className="sm:w-48">Buy {token_ticker}</Button>
                    </a>
                </Link>
            </div>
        </div>
    );
};

export default NoStrat;

export const CardItem: React.FC<{
    title: string | number;
    subtitle: string;
    id?: string;
}> = ({ title, subtitle, id }) => (
    <div className="flex-1 rounded bg-main px-8 py-4">
        <h2 className="text-2xl" id="wallet_no">
            <strong data-testid={id}>{title}</strong>
        </h2>
        <p className="mt-2 text-secondary text-xs">{subtitle.toUpperCase()}</p>
    </div>
);

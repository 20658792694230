import React, { useRef } from "react";
import useSmoothScroll from "react-smooth-scroll-hook";

export const RadioButtons: React.FC<{
    options: { id: number; label: string }[];
    curr: number;
    // eslint-disable-next-line
    setCurr: any;
}> = ({ options = [], curr, setCurr }) => {
    const ref = useRef(null);
    const { scrollTo } = useSmoothScroll({
        ref,
        speed: 100,
        direction: "x",
    });
    const handleClick = (id) => {
        const rect = document
            ?.querySelector(`#strategy-${id}`)
            .getBoundingClientRect();
        scrollTo(`#strategy-${id}`, -rect.width / 2);
        setCurr(id);
    };

    return (
        <div
            className="flex w-full text-center bg-level-one mb-10 cursor-pointer justify-start rounded overflow-x-auto border border-white border-opacity-5"
            id="radio-button-container"
            ref={ref}
        >
            {options.map(({ id, label }) => (
                <button
                    key={label}
                    id={`strategy-${id}`}
                    onClick={() => handleClick(id)}
                    className={`min-w-max py-4 px-5 sm:px-10 mx-5 sm:mx-10 ${
                        curr === id
                            ? "border-b-4 border-accent"
                            : "border-0 text-secondary"
                    }`}
                >
                    <div className="w-36 sm:w-auto sm:min-w-max">
                        {(curr === id && (
                            <strong className="text-md">{label}</strong>
                        )) ||
                            label}
                    </div>
                </button>
            ))}
        </div>
    );
};

import {
    Action,
    createSlice,
    PayloadAction,
    ThunkDispatch,
} from "@reduxjs/toolkit";
import { Contract } from "@ethersproject/contracts";

import { ContractStrategy, Strategy, UserVesting } from "@src/ts/interfaces";
import { RootState } from "@src/store";
import { mapContractStrategy } from "@src/util";
import { VESTING_DETAILS } from "@src/config";
import { BigNumber } from "@ethersproject/bignumber";

const initial_strats = VESTING_DETAILS.map((_, idx) => idx).reduce(
    (acc, curr) => ({
        ...acc,
        [curr]: [],
    }),
    {},
);

const initialState = {
    strategies: initial_strats,
    loading: false,
    processing: false,
    attempted: true,
    curr_id: null,
} as {
    strategies: {
        [idx: number]: UserVesting[];
    };
    loading: boolean;
    processing: boolean;
    curr_id: number;
    attempted: boolean;
};

const vestingSlice = createSlice({
    name: "vesting",
    initialState,
    reducers: {
        setAttempted(state, action: PayloadAction<boolean>) {
            state.attempted = action.payload;
        },
        setCurrId(state, action: PayloadAction<number>) {
            state.curr_id = action.payload;
        },
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },
        setProcessing(state, action: PayloadAction<boolean>) {
            state.processing = action.payload;
        },
        setStrategies(
            state,
            action: PayloadAction<{
                strategies: Strategy[];
                idx: number;
            }>,
        ) {
            const { idx, strategies } = action.payload;
            state.strategies[idx] = strategies.map((s) => ({
                total_vesting: BigNumber.from(0),
                already_released: BigNumber.from(0),
                releasable: BigNumber.from(0),
                revoked: false,
                disabled: false,
                ...s, // default values to be overwritten if available in strategy
            }));
        },
        setStrategy(
            state,
            action: PayloadAction<{
                strategy: UserVesting;
                idx: number;
            }>,
        ) {
            const { idx, strategy } = action.payload;
            const next_strategies = [...state.strategies[idx]];

            const index = next_strategies.findIndex(
                ({ id }) => id === strategy.id,
            );

            next_strategies[index] = strategy;

            state.strategies[idx] = next_strategies;
        },
    },
});

const mapUserVesting = async (
    contract: Contract,
    strat: Strategy,
    account: string,
) => {
    const whitelist = await contract.getWhitelist(strat.id, account);

    let releasable = BigNumber.from(0);
    try {
        releasable = await contract.getReleasableAmount(strat.id, account);
    } catch (err) {
        console.error(err);
    }

    return {
        ...strat,
        total_vesting: whitelist.dcbAmount,
        already_released: whitelist.distributedAmount,
        releasable,
        revoked: whitelist.revoke,
        disabled: whitelist.disabled,
    };
};

export const updateStrategies = (
    contract: Contract,
    account: string,
    idx: number,
) => {
    return (dispatch: ThunkDispatch<RootState, void, Action>): void => {
        dispatch(setAttempted(true));
        dispatch(setLoading(true));

        contract
            .getAllVestingPools()
            .then(async (all_strategies: ContractStrategy[]) => {
                const strategies = (
                    await Promise.all(
                        all_strategies.map(async (s, id) => {
                            const res = await contract.hasWhitelist(
                                id,
                                account,
                            );
                            return [{ ...s, id }, res];
                        }),
                    )
                )
                    // eslint-disable-next-line
                    .filter(([_, r]) => r)
                    .map(([s]) => s)
                    .map((s) => mapContractStrategy(s));

                const user_strategies = await Promise.all(
                    strategies.map((strat) =>
                        mapUserVesting(contract, strat, account),
                    ),
                );
                dispatch(setStrategies({ strategies: user_strategies, idx }));
                dispatch(setLoading(false));
            });
    };
};

export const updateStrategy = (
    contract: Contract,
    account: string,
    idx: number,
    id: number,
) => {
    return (dispatch: ThunkDispatch<RootState, void, Action>): void => {
        dispatch(setProcessing(true));
        contract.getVestingInfo(id).then(async (strat: ContractStrategy) => {
            const strategy = await mapUserVesting(
                contract,
                mapContractStrategy({ ...strat, id }),
                account,
            );

            dispatch(setStrategy({ strategy, idx }));
            dispatch(setProcessing(false));
        });
    };
};

export const {
    setAttempted,
    setCurrId,
    setLoading,
    setProcessing,
    setStrategies,
    setStrategy,
} = vestingSlice.actions;
export default vestingSlice.reducer;

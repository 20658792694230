import React, { useState } from "react";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";

import { InfoText } from "@src/components/info-text";
import { IS_DARK_MODE } from "@src/config";

dayjs.extend(timezone);

export type InputType = "number" | "text" | "datetime-local";

export interface InputProps
    extends React.InputHTMLAttributes<HTMLInputElement> {
    name: string;
    label?: string;
    inputClassName?: string;
    error?: string;
    inset?: JSX.Element;
    tooltip?: string;
}

export const Input: React.FC<InputProps> = ({
    className,
    id,
    inputClassName,
    label,
    name,
    onChange,
    placeholder,
    type,
    value,
    error,
    inset,
    tooltip,
    readOnly,
}) => {
    const [focused, setFocused] = useState(false);

    if (type === "checkbox") {
        return (
            <div>
                <div className="flex items-cenetr">
                    {label && (
                        <label
                            className="text-sm text-secondary mb-2"
                            htmlFor={id}
                        >
                            {tooltip ? (
                                <InfoText text={label} info={tooltip} />
                            ) : (
                                label
                            )}
                        </label>
                    )}
                    {error && <p className="text-red">{error}</p>}
                </div>

                <div className="flex items-center bg-level-two rounded px-4 py-2 space-x-4 shadow py-3">
                    <div className="flex-1">{placeholder}</div>{" "}
                    <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                        <input
                            type="checkbox"
                            className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-primary border-4 appearance-none cursor-pointer"
                            name={name}
                            id={id}
                            data-testid={id || "input"}
                            value={value}
                            onChange={onChange}
                            checked={Boolean(value)}
                            readOnly={readOnly}
                        />
                        <label
                            htmlFor="toggle"
                            className="toggle-label block overflow-hidden h-6 rounded-full bg-main cursor-pointer"
                        ></label>
                    </div>
                </div>
            </div>
        );
    }

    const container_classes = `flex items-center rounded  ${
        inputClassName || ""
    } ${
        inset
            ? `bg-main shadow border border-primary ${
                  (!focused && "border-opacity-5") || ""
              }`
            : ""
    }`;
    const input_classes = `outline-none bg-main flex-1 w-full py-3 px-3 rounded dark-placeholder  ${
        IS_DARK_MODE ? "dark-mode" : ""
    } ${
        inset
            ? ``
            : `border shadow border-primary ${
                  (!focused && "border-opacity-5") || ""
              }`
    }`;

    return (
        <div data-testid="input-div" className={`${className || ""}`}>
            <div className="flex space-between items-center text-secondary">
                {label && (
                    <label className="flex-1 text-sm mb-2" htmlFor={id}>
                        {tooltip ? (
                            <InfoText text={label} info={tooltip} />
                        ) : (
                            label
                        )}{" "}
                    </label>
                )}
                {!!error && (
                    <p className="text-red-500" id="error_message">
                        {error}
                    </p>
                )}
            </div>
            <div className={container_classes}>
                <input
                    onWheel={(event) => event.currentTarget.blur()}
                    data-testid={id || "input"}
                    onFocus={() => setFocused(true)}
                    onBlur={() => {
                        setFocused(false);
                    }}
                    className={input_classes}
                    id={id}
                    name={name}
                    onChange={onChange}
                    type={type}
                    value={value}
                    placeholder={placeholder}
                    readOnly={readOnly}
                />
                {inset && inset}
            </div>
        </div>
    );
};

Input.defaultProps = {
    type: "text",
};

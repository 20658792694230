import React from "react";
import dayjs from "dayjs";
import { UserVesting } from "@src/ts/interfaces";

import { getEndDate } from "@src/util";
import { IS_DIFFERENT_ACCENT } from "@src/config";

const formatDate = (d: string) => dayjs(d).format("DD/MM/YY");
const formatTime = (d: string) => dayjs(d).format("HH:mm");

const formatDateAndTime = (d: string) => (
    <>
        {formatDate(d)} |{" "}
        <span
            className={`${
                IS_DIFFERENT_ACCENT ? "text-[#a69999]" : "text-accent"
            }`}
        >
            {formatTime(d)}
        </span>
    </>
);

export const VestingPeriod: React.FC<{ current: UserVesting }> = ({
    current,
}) => {
    const end_date = getEndDate(current);

    return (
        <div className="mb-10 w-full md:w-1/3 md:ml-2 text-left">
            <h3
                className="text-lg
             mb-10 font-medium"
            >
                Vesting Period
            </h3>
            <div className="bg-level-one py-3 px-5 my-2 rounded border border-white border-opacity-5 md:h-48 flex items-center">
                <div className="flex flex-1 items-center justify-between my-3">
                    <div>
                        <p className="my-3">Start date</p>
                        <p className="my-3">Cliff</p>
                        <p className="my-3">End date</p>
                    </div>
                    <div className="text-right text-secondary">
                        <p className="my-3">
                            {current?.start_date
                                ? formatDateAndTime(current.start_date)
                                : "None"}
                        </p>
                        <p className="my-3">
                            {current?.cliff
                                ? formatDateAndTime(current.cliff)
                                : "None"}
                        </p>
                        <p className="my-3">
                            {end_date ? formatDateAndTime(end_date) : "None"}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

import { useState } from "react";

import { Switch } from "@src/components/switch";
import { StrategyManagementProps } from "@src/ts/props";

import { StrategyForm } from "./StrategyForm";
import { WalletManagement } from "./WalletManagement";

const nav_opts = ["Strategy setup", "Manage wallets"];
export const StrategyManagement: React.FC<StrategyManagementProps> = ({
    idx,
    strategy_id,
    state,
    handleChange,
    add = false,
    handleClick,
    errors,
    loading,
    disabled,
}) => {
    const [nav, setNav] = useState(0);

    return (
        <div className="bg-level-one rounded p-5 border border-white border-opacity-5 mb-40">
            <Switch options={nav_opts} current={nav} setCurrent={setNav} />

            {nav === 0 ? (
                <StrategyForm
                    state={state}
                    handleChange={handleChange}
                    add={add}
                    handleClick={handleClick}
                    errors={errors}
                    loading={loading}
                    disabled={disabled}
                />
            ) : (
                <WalletManagement
                    idx={idx}
                    strategy_id={strategy_id}
                    add={add}
                    back={() => setNav(0)}
                    revocable={Boolean(state.revocable)}
                />
            )}
        </div>
    );
};

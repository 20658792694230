import React from "react";
import Link from "next/link";

import { Button } from "@src/components";
import { PlusIcon } from "@src/components/Icon";

export const AddStrategyButton: React.FC<{
    className?: string;
    idx: number;
}> = ({ className, idx }) => (
    <Link href={`/manage/${idx}/strategy/add`}>
        <a>
            <Button
                className={`flex items-center space-x-2 ${className || ""}`}
                id="add_button"
            >
                <PlusIcon />
                <div>Add new strategy</div>
            </Button>
        </a>
    </Link>
);

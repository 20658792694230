import { Loader } from "@src/components/loader";

export const PageLoader: React.FC = () => {
    return (
        <div
            className="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover"
            id="modal-id"
            data-testid="pageloader-div"
        >
            <div className="absolute bg-black opacity-95 inset-0 z-0"></div>
            <div className="loader">
                <Loader height={20} />
            </div>
        </div>
    );
};
